/* © 2017 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, January 01, 2017
 * For information or permission request, email info@aetonixsystems.com
 */

import Store from "./store";

import values from "object-values";
import makeStore from "../shared/make_store";
import bloodOxygenHistoryStore from "./health_bloodoxygen_store";
import bodyTemperatureHistoryStore from "./health_bodytemperature_store";
import bloodSugarHistoryStore from "./health_bloodsugar_store";
import weightHistoryStore from "./health_weight_store";
import bloodPressureHistoryStore from "./health_bloodpressure_store";
import stepsHistoryStore from "./activity_steps_store";
import CustomIndicatorHistoryStore from "./health_custom_indicator_store";
import OrgIndicatorStore from "./org_custom_indicators_store";

var STORES_MAP = {
	bloodOxygen: bloodOxygenHistoryStore,
	bodyTemperature: bodyTemperatureHistoryStore,
	bloodSugar: bloodSugarHistoryStore,
	weight: weightHistoryStore,
	bloodPressure: bloodPressureHistoryStore,
	activity: stepsHistoryStore
};
const defaultPage = 1;
const defaultLimit = 30;

export default UserObservationsStore;

function UserObservationsStore(api, events, config) {
	var store = new Store();

	const definedStores = Object.keys(STORES_MAP).reduce(function(acc, key) {
		acc[key] = makeStore(STORES_MAP[key])(api, events, config);
		return acc;
	}, {});

	const orgIndicatorStore = makeStore(OrgIndicatorStore)(api, events, config);
	orgIndicatorStore.listen(updateIndicators);

	let stores = definedStores;

	var days = null;
	var recentRecords = null;
	var id = null;
	let loadMorePage = 1;
	store.init = init;
	store.more = more;
	store.days = getDays;
	store.recentRecords = getRecentRecords;
	store.indicators = [];
	store.stores = stores;

	return store;

	function init(who) {
		id = who;
		initRecentRecords(who);
		initDays(who);
		track(who);
	}

	function updateIndicators(indicatorStore) {
		const indicators = indicatorStore?.all?.() ?? [];

		store.indicators = indicators;

		const storesForIndicators = indicators.reduce((accum, indicator) => {
			if (!indicator || !indicator._id || indicator._id === "undefined") {
				return accum;
			}

			const customIndicatorStore = makeStore(CustomIndicatorHistoryStore)(api, events, config);
			customIndicatorStore.store.indicator(indicator._id);

			return { ...accum, [indicator._id]: customIndicatorStore };
		}, {});

		stores = { ...definedStores, ...storesForIndicators };
		store.stores = stores;

		values(stores).forEach(s => {
			s.store.searchQuery(id);
		});

		track(id);
	}

	function track(user) {
		const orgGroup = config.orgGroup;

		if (!orgGroup) {
			return;
		}

		events.on(
			"mqtt:orggroup/" + orgGroup + "/u/" + user + "/observations/blood_oxygen",
			updateDays
		);
		events.on(
			"mqtt:orggroup/" + orgGroup + "/u/" + user + "/observations/blood_pressure",
			updateDays
		);
		events.on(
			"mqtt:orggroup/" + orgGroup + "/u/" + user + "/observations/bloodsugar",
			updateDays
		);
		events.on(
			"mqtt:orggroup/" + orgGroup + "/u/" + user + "/observations/body_temperature",
			updateDays
		);
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + user + "/observations/weight", updateDays);

		events.on(
			"mqtt:orggroup/" + orgGroup + "/u/" + user + "/observations/steps/cumulant",
			updateDays
		);

		events.on(
			"mqtt:orggroup/" + orgGroup + "/u/" + user + "/indicator/updated",
			updateIndicator
		);

		store?.indicators?.forEach?.(indicator => {
			events.on(
				`mqtt:orggroup/${orgGroup}/u/${user}/observations/indicators/${indicator._id}`,
				updateDays
			);
		});
	}

	function getDays() {
		return sortMap(days);
	}

	function getRecentRecords() {
		return recentRecords;
	}

	async function initRecentRecords(userId) {
		const records = await api.org.users.recentReadings.ofUsers(userId);
		let recentRecord = records[0];
		const indicators = store.indicators;
		const customHealthKeys = recentRecord?.healthData.customHealthData ? Object.keys(recentRecord.healthData.customHealthData) : [];

		indicators.map(
			indicator => {
				const indicatorStore = customHealthKeys.filter(customIndicator => customIndicator === indicator._id).length;
				if (!indicatorStore) {
					if(!recentRecord.healthData.customHealthData) recentRecord.healthData.customHealthData = {};
					recentRecord.healthData.customHealthData[indicator._id] = {};
				}
		});

		recentRecords = recentRecord;
		store.set("recentRecords", recentRecord);
	}

	async function initDays(userId) {
		const timeOffset = new Date().getTimezoneOffset();
		let records = await api.org.handleDaily(userId, defaultPage, defaultLimit, timeOffset);
		days = new Map();
		records.forEach(r => {
			const { _id, ...rest } = r;
			days.set(formatDate(new Date(_id)), rest);
		});

		store.set("days", days);
	}

	async function more(userId, limit) {
		loadMorePage++;
		const timeOffset = new Date().getTimezoneOffset();
		const records = await api.org.handleDaily(userId, loadMorePage, limit, timeOffset);
		const newDays = new Map();
		records.forEach(r => {
			const { _id, ...rest } = r;
			days.set(formatDate(new Date(_id)), rest);
		});

		store.set("days", new Map([...days, ...newDays]));
		return records.length;
	}

	async function updateDays(){
		const timeOffset = new Date().getTimezoneOffset();
		const records = await api.org.handleDaily(id, 1, defaultLimit, timeOffset);
		const currentDays = store.get("days");
		const today = formatDate(new Date());
		const todayRecord = records.find(r => formatDate(new Date(r._id)) === today);

		const { ...rest } = todayRecord;
		currentDays.set(today, rest);

		initRecentRecords(id);
		store.set("days", currentDays);
	}

	function updateIndicator(day) {
		const date = formatDate(day.created_at ? new Date(day.created_at) : new Date());
		let currentDays = store.get("days");
		let currentRecord = currentDays.get(date);

		const keysToUpdate = Object.keys(currentRecord).filter(key => {
			const record = currentRecord[key];
			return day?.type?.includes?.(record.indicatorType) && day?._id === record?._id;
		});

		if(keysToUpdate.length){
			const newDay = { ...currentRecord };

			keysToUpdate.forEach(key => {
				newDay[key] = {
					...currentRecord[key],
					invalid: day.invalid,
					notes: day.notes
				};
			});

			initRecentRecords(id);
			currentDays.set(date, newDay);
			store.set("days", currentDays);
		}
	}
}

function formatDate(date, ignoreToday) {
	if (isToday(date) && !ignoreToday) return "today";
	var month = date.getMonth() + 1;
	var day = date.getDate();
	var year = date.getFullYear();

	if (month < 10) month = "0" + month;
	if (day < 10) day = "0" + day;

	return month + "/" + day + "/" + year;
}

function sortMap(map) {
	if (!map) return new Map([]);
	return new Map([...map].sort(sortArr));
}

function sortArr(a, b) {
	var aKey = a[0];
	var bKey = b[0];

	if (aKey === "today") return -1;
	if (bKey === "today") return 1;

	var aDate = new Date(aKey);
	var bDate = new Date(bKey);

	return bDate - aDate;
}

function isToday(date) {
	var today = new Date();
	var isMonth = today.getMonth() === date.getMonth();
	var isDay = today.getDate() === date.getDate();
	var isYear = today.getFullYear() === date.getFullYear();
	return isDay && isMonth && isYear;
}